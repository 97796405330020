import moment from "moment";
import spriteNative from "./assets/nativeIcons/icons.svg";
import { getLocalizedString } from "../../shared/translation";

//Time Status
export const getTimeGap = (sourceTime) => {
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const date = new Date();
  let currentTime = date.getTime();
  let timeGap = currentTime - sourceTime;
  let activityTimeMap = {
    minutes: Math.round(timeGap / minute),
    hours: timeGap / hour,
    days: Math.round(timeGap / day),
  };
  let status;
  let description;
  let activeWithin;
  if (sourceTime) {
    status = "active";
    description = "Active";
    activeWithin = "Active Now";
    if (activityTimeMap.minutes >= 1 && activityTimeMap.minutes <= 59) {
      if (activityTimeMap.minutes < 5) {
        status = "active";
        description = "Active";
      } else {
        status = "idle";
        description = "Idle";
      }
      if (activityTimeMap.minutes == 1) {
        activeWithin = `Active ${activityTimeMap.minutes} min ago`;
      } else {
        activeWithin = `Active ${activityTimeMap.minutes} mins ago`;
      }
    } else if (activityTimeMap.minutes >= 59 && activityTimeMap.hours < 24) {
      status = "offline";
      description = "Recently Active";
      let hoursText = "";
      let minutesText = "";
      if (Math.floor(activityTimeMap.hours) == 1) {
        hoursText = "hr";
      } else {
        hoursText = "hrs";
      }

      if (activityTimeMap.minutes % 60 == 1) {
        minutesText = "min";
      } else {
        minutesText = "mins";
      }
      activeWithin = `Active ${Math.floor(activityTimeMap.hours)} ${hoursText} ${
        activityTimeMap.minutes % 60 == 0 ? "" : `${activityTimeMap.minutes % 60} ${minutesText}`
      } ago`;
    } else if (activityTimeMap.days >= 1 && activityTimeMap.days < 2) {
      status = "offline";
      description = "Active within 2 days";
      let hoursText = "";
      if (Math.floor(activityTimeMap.hours % 24) == 1) {
        hoursText = "hr";
      } else {
        hoursText = "hrs";
      }
      activeWithin = `Active ${Math.floor(activityTimeMap.days)} day ${
        Math.floor(activityTimeMap.hours % 24) == 0
          ? ""
          : `${Math.floor(activityTimeMap.hours % 24)} ${hoursText}`
      } ago`;
    } else if (activityTimeMap.days >= 2) {
      status = "offline";
      description = "Inactive";
      activeWithin = `${moment(sourceTime).format("DD/MM/YY - hh:mm A")}`;
    }
  } else {
    status = "offline";
    description = "Inactive";
    activeWithin = getLocalizedString("no_recorded_time", "No Recorded Time");
  }
  return {
    status,
    description,
    activeWithin,
  };
};

//Ignition Status
export const getIgnitionStatus = (ignition_data) => {
  let ignition = ignition_data;
  //Need to check why converted to upper case
  if (typeof ignition === "string") {
    ignition = ignition?.toUpperCase();
  } else {
    ignition = String(ignition).toUpperCase();
  }
  if (ignition === "TRUE" || ignition === "ON" || ignition === true) {
    ignition = "On";
  } else if (ignition === "FALSE" || ignition === "OFF" || ignition === false) {
    ignition = "Off";
  } else {
    ignition = "Not Available";
  }
  return ignition;
};

//Ignition Status
export const ignitionStatus = (ignition) => {
  //Need to check why converted to upper case
  if (typeof ignition === "string") {
    ignition = ignition?.toUpperCase();
  }
  if (ignition === "TRUE" || ignition === "ON" || ignition === true) {
    return (
      <span className="ignition on" title={"On"}>
        <svg className="icon engine on">
          <use href={`${spriteNative}#engine`}></use>
        </svg>
      </span>
    );
  } else if (ignition === "UNKNOWN") {
    return (
      <span className="ignition unknown" title={"This device doesn't support ignition status"}>
        <svg className="icon engine unknown">
          <use href={`${spriteNative}#engine`}></use>
        </svg>
      </span>
    );
  } else {
    return (
      <span className="ignition off" title={"Off"}>
        <svg className="icon engine off">
          <use href={`${spriteNative}#engine`}></use>
        </svg>
      </span>
    );
  }
};

//Remoes duplicate objects in an array (retains first occurence)
export const removeDuplicates = (arr) => {
  const uniqueObject = {};
  const uniqueArray = [];

  arr.forEach((obj) => {
    const strObj = JSON.stringify(obj);
    if (!uniqueObject[strObj]) {
      uniqueObject[strObj] = true;
      uniqueArray.push(obj);
    }
  });

  return uniqueArray;
};

//Calculate Distance with Lat,Lng
export function calculateDistance(gps_distance) {
  if (gps_distance > 1000) {
    return (gps_distance / 1000).toFixed(1) + " km";
  } else {
    return gps_distance.toFixed(1) + " m";
  }
}

//events type
export function eventsType(event) {
  switch (event) {
    case "over_speed":
      return "Speeding";
    case "New":
      return "Started";
    case "Completed":
      return "Ended";
    case "":
      return "NA";
    case null:
      return "NA";
    case true:
      return "True";
    case false:
      return "False";
    default:
      return capitalizeAllFirstLetters(event ? event : "");
  }
}
/* Trips,Events & Activities */
export function extractKeyValuePairs(obj, parentKey = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    if (obj[key] && typeof obj[key] === "object") {
      return acc.concat(extractKeyValuePairs(obj[key], currentKey));
    }
    return acc.concat({ [currentKey]: obj[key] });
  }, []);
}

/* Track Points */
export function extractKeyValuePairsTrackPoints(obj, parentKey = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const currentKey = parentKey ? `${key}` : key;
    if (obj[key] && typeof obj[key] === "object") {
      return acc.concat(extractKeyValuePairsTrackPoints(obj[key], currentKey));
    }
    return acc.concat({ [currentKey]: obj[key] });
  }, []);
}

export function capitalizeFirstLetter(string) {
  const updatedString = String(string).split("_").join(" ");
  return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
}
//Capitalize
export function capitalize(str) {
  return str && str[0].toUpperCase() + str.slice(1);
}

//Replace underscore with space and start each word in upper case
export function capitalizeAllFirstLetters(string) {
  const originalString = String(string).split(/[_\.]/).join(" ");
  const words = originalString.split(" ");
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

export function getDuration(value, type = "ms") {
  let duration;

  switch (type) {
    case "ms":
      duration = value;
      break;
    case "s":
      duration = value * 1000;
      break;
    case "h":
      duration = value * 60 * 60 * 1000;
      break;
    case "min":
      duration = value * 60 * 1000;
      break;
    default:
      break;
  }

  const momentDuration = moment.duration(duration);
  const days = Math.floor(momentDuration.asDays());
  const hours = Math.floor(momentDuration.asHours());
  const minutes = momentDuration.minutes();
  const seconds = momentDuration.seconds();

  const padZero = (num) => (num < 10 ? "0" + num : num);

  let formatted;

  if (hours >= 24) {
    formatted = `${padZero(days)}d ${padZero(hours - days * 24)}:${padZero(minutes)}`;
  } else {
    formatted = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  return formatted;
}

export function flattenObject(obj, parentKey = "") {
  let result = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
        // Recursively flatten nested objects
        const flattened = flattenObject(obj[key], newKey);
        result = { ...result, ...flattened };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}

export function removeDuplicateLabels(arr) {
  const uniqueLabels = {};
  return arr.filter((item) => {
    if (!uniqueLabels[item.label]) {
      uniqueLabels[item.label] = true;
      return true;
    }
    return false;
  });
}
//Remove duplicates in arrey of objects (retains first occurence)
export function uniqueArray(data) {
  return data.reduce((acc, current) => {
    const x = acc.find((item) => item.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
}

export function keyFormatter(key) {
  switch (key) {
    case "name":
      return "address";
    default:
      return key;
  }
}

export function roundOf(value, variable, defaultPrecision = null) {
  if (typeof value === "number") {
    if (variable?.variable_type === "Number") {
      if (variable.precision != null) {
        return value?.toFixed(variable?.precision);
      } else if (defaultPrecision >= 0) {
        return value?.toFixed(defaultPrecision);
      }
    }
  }
  return value;
}

export function valueFormatter(key, value, variableData) {
  if (variableData) {
    const variable = variableData[key];
    if (variable?.variable_type === "Timestamp") {
      value = moment(Number(value))?.format(" DD/MM/YYYY - hh:mm:ss A");
      return capitalizeFirstLetter(String(value));
    } else if (variable?.unit_value === "m/s") {
      return roundOf(Number(value) * 3.6, variable, 1) + " km/h";
    } else if (
      (variable?.variable_type === "Duration" || variable?.variable_type === "Number") &&
      (variable?.unit_value === "s" || variable?.unit_value === "ms")
    ) {
      return getDuration(roundOf(Number(value), variable), variable?.unit_value);
    } else if (variable?.unit_value === "m" && variable?.variable_type === "Number") {
      if (value >= 10000) {
        return roundOf(value / 1000, variable, 0) + " km";
      } else if (value >= 1000 && value < 10000) {
        return roundOf(value / 1000, variable, 1) + " km";
      } else {
        if (value) {
          if (typeof value === "number") {
            if (variable?.variable_type === "Number") {
              value = roundOf(value, variable, 2);
            } else {
              value = value?.toFixed(2);
            }
          }
        }
      }
    } else if (variable?.variable_type === "Number") {
      value = roundOf(value, variable);
    }
  } else {
    return `${capitalizeFirstLetter(String(value))} ${
      (value && value !== "NA") || value == 0 ? displayUnitName(variableData, key) : ""
    }`;
  }

  return `${capitalizeFirstLetter(String(value))} ${
    (value && value !== "NA") || value == 0 ? displayUnitName(variableData, key) : ""
  }`;
}

export const displayKeyName = (variableData, key) => {
  if (variableData) {
    return variableData[key]?.display_name
      ? variableData[key]?.display_name
      : capitalizeAllFirstLetters(key)
      ? capitalizeAllFirstLetters(key)
      : null;
  } else {
    return capitalizeAllFirstLetters(key);
  }
};

export const displayUnitName = (variableData, key) => {
  return variableData && variableData[key]?.unit_value ? variableData[key]?.unit_value : "";
};

export const displayTableConfigName = (variableData, key) => {
  if (variableData && variableData[key]?.display_name) {
    return `${variableData[key]?.display_name} ${
      variableData[key]?.section_name
        ? `(${variableData[key]?.section_name}${
            variableData[key]?.unit_name !== "Not applicable"
              ? "- " + variableData[key]?.unit_name
              : ""
          })`
        : ""
    }`;
  } else {
    return capitalizeAllFirstLetters(key);
  }
};

export function removeArrayValues(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      delete obj[key];
    }
  }
  return obj;
}

export function spreadDeviceDataObject(obj) {
  const result = {};

  function spreadHelper(currentObj, parentKey = "") {
    for (const key in currentObj) {
      if (Array.isArray(currentObj[key]?.v)) {
        currentObj[key]?.v?.forEach((item) => {
          spreadHelper(item, parentKey ? `${key}` : key);
        });
      }
      if (key === "t") {
        continue;
      }
      if (typeof currentObj[key] === "object" && !Array.isArray(currentObj[key])) {
        spreadHelper(currentObj[key], parentKey ? `${parentKey}.${key}` : key);
      } else {
        if (key === "v") {
          const newKey = parentKey || key;
          result[newKey.replace(".v.", ".")] = currentObj[key];
        } else {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          result[newKey.replace(".v.", ".")] = currentObj[key];
        }
      }
    }
  }

  spreadHelper(obj);
  return removeArrayValues(result);
}

export function getValueByNestedKey(obj, key) {
  const keys = key.split(".");
  let value = obj;
  for (const k of keys) {
    value = value?.[k];
    if (value === undefined) {
      break;
    }
  }
  return value;
}

export function removeConsecutiveDuplicates(arr) {
  let result = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== arr[i + 1]) {
      result.push(arr[i]);
    }
  }
  return result;
}

export function msToKmph(mps) {
  return mps * 3.6;
}

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
