import React, { useState } from "react";
import { ListCard, MapViewFlyOut } from "../Shared";
import { useGetDevicesQuery } from "../../ManagementPortal/services";
import { useGetVehiclesQuery } from "../../ManagementPortal/services";
import { useGetDevicesTrackingQuery } from "../services";
import { QIExpand } from "../../../components";
import { useEffect } from "react";
import { getTimeGap, ignitionStatus } from "../utils";
import { smallLogo } from "../../../assets";
import moment from "moment";
import { useSearchQuery } from "../../../hooks";
import { FMDPStorage } from "../../../shared/helper";
import { useDispatch } from "react-redux";
import { setSourceIdsGlobal } from "../../../reduxStore/features";
import { getLocalizedString } from "../../../shared/translation";

import sprite from "../../../assets/icons.svg";

import spriteNative from "../assets/nativeIcons/icons.svg";
import { list } from "postcss";
import { data } from "jquery";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import Scrollbars from "react-custom-scrollbars";

let list_arr = [];
let filteredListArr = [];
export const VehicleList = ({
  setDetails,
  setDeviceId,
  setSourceIds,
  showPontOnMap,
  mapData,
  setselectedVehiclePlate,
  setHighlight,
  highlight,
  setDockAlign,
  setShowDetails,
  removeLayerOnClose,
  setMapExpand,
  setShowTrack,
  dockAlign,
  setSimpleSearch,
  simpleSearch,
  currentTab,
  setVehicleData,
  selectedRowId,
  setSelectedRowId,
  vehicleData,
  setCurrentTab = () => {},
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [q, setQ] = useState("");

  const currentUser = FMDPStorage.get("current-user");
  const selectedAppId = FMDPStorage.get("selected-app-id");
  const dispatch = useDispatch();
  // const [highlight, setHighlight] = useState(null);
  useEffect(() => {
    let obj = {
      name_or_plate_number_cont: simpleSearch.toUpperCase(),
      filter_device_imei_cont: simpleSearch.toUpperCase(),
      m: "or",
    };
    setPage(1);
    setQ(encodeURIComponent(JSON.stringify(obj)));
  }, [simpleSearch]);

  useEffect(() => {
    let obj = {
      name_or_plate_number_cont: simpleSearch.toUpperCase(),
      filter_device_imei_cont: simpleSearch.toUpperCase(),
      m: "or",
    };
    setQ(encodeURIComponent(JSON.stringify(obj)));
  }, [simpleSearch]);

  // For page change set the map as default tab
  useEffect(() => {
    setCurrentTab("Map");
    setShowTrack(false);
    setDockAlign("cross");
  }, [page]);

  const getUserId = () => {
    const currentAppRoles = currentUser?.client_app_roles;
    const currentUserId = currentUser?.id;
    let hasAdminRole = false;
    currentAppRoles?.filter((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.filter((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });
    if (hasAdminRole === false) {
      return currentUserId;
    } else {
      return "";
    }
  };

  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };
  const {
    data: coreDevices = { data: [], total_count: 0 },
    error,
    isLoading,
  } = useGetVehiclesQuery({
    page,
    per_page: perPage,
    q,
    filter_user_id: getUserId(),
  });

  const { data: devicesList } = useGetVehiclesQuery({
    page,
    per_page: 10000,
    q,
    filter_user_id: getUserId(),
  });

  // If the user is non-admin, store the plate numbers which that particular user has
  useEffect(() => {
    if (getUserId()) {
      FMDPStorage.set(
        "selected-plate-numbers",
        devicesList?.data?.map((device) => {
          return device?.plate_number;
        })
      );
    }
  }, [devicesList]);

  useEffect(() => {
    try {
      list_arr = [];
      coreDevices?.data.forEach((element, index) => {
        let x = { className: element.device_name_list + "_cls" };
        list_arr.push({ ...element, ...x });
      });

      let devices = coreDevices?.data.map(({ device_name_list }) => device_name_list);
      setSourceIds((prev) => devices);
    } catch (e) {}
  }, [coreDevices.data, simpleSearch]);

  useEffect(() => {
    try {
      const device_name_list_arr = devicesList?.data?.map((elem, id) => {
        return elem.device_name_list;
      });
      const plate_no_list_arr = devicesList?.data?.map((elem, id) => {
        return elem.plate_number;
      });
      dispatch(setSourceIdsGlobal([device_name_list_arr, plate_no_list_arr]));
    } catch (e) {
      console.log(e);
    }
  }, [devicesList, dispatch]);

  const onRowClick = (data, id) => {
    if (selectedRowId === data?.id) {
      setSelectedRowId(null);
      removeLayerOnClose();
      setDetails(false);
      setDeviceId(null);
      setDockAlign("cross");
    } else {
      setSelectedRowId(data?.id);
      currentTab !== "TrackPoints" &&
        currentTab !== "Trips" &&
        currentTab !== "Activities" &&
        currentTab !== "Events" &&
        setDetails(true);
      removeLayerOnClose();
      setDeviceId(data?.device_name_list);
      setselectedVehiclePlate(data?.plate_number);
      try {
        showPontOnMap(data?.device_name_list, data?.plate_number);
      } catch (e) {}

      dockAlign !== "pop-out" && setDockAlign("split-down");
    }
  };

  useEffect(() => {
    try {
      if (!simpleSearch) {
        setSelectedRowId(null);
        removeLayerOnClose();
        setDetails(false);
        setDeviceId(null);
        setDockAlign("cross");
      }
    } catch (e) {
      console.log(e);
    }
  }, [page, simpleSearch]);

  return (
    <>
      <MapViewFlyOut
        data={coreDevices?.data}
        setSelectedRange={setSelectionRange}
        pagination={{ ...pagination, count: coreDevices?.total_count }}
        error={error}
        highlight={highlight}
        setHighlight={setHighlight}
        setDockAlign={setDockAlign}
        setShowDetails={setShowDetails}
        removeLayerOnClose={removeLayerOnClose}
        setDeviceId={setDeviceId}
        setShowTrack={setShowTrack}
        simpleSearch={simpleSearch}
        selectedPage={"Vehicles"}
        title={getLocalizedString("vehicles", "Vehicles")}
        currentTab={currentTab}
        setselectedVehiclePlate={setselectedVehiclePlate}
        showPontOnMap={showPontOnMap}
        dockAlign={dockAlign}
        setDetails={setDetails}
        setSimpleSearch={setSimpleSearch}
        setMapExpand={setMapExpand}
        listType={"Vehicles"}
        isLoading={isLoading}
        search_by_message={getLocalizedString(
          "search_by_plate_no_device_imei",
          "Search by Plate No, Device IMEI"
        )}
      >
        <Scrollbars>
          <ListCard
            data={coreDevices?.data || []}
            cardType="Vehicles"
            onRowClick={onRowClick}
            highlight={highlight}
            setHighlight={setHighlight}
            selectedRowId={selectedRowId}
            mapData={mapData}
            setvehicleData={setVehicleData}
          />
        </Scrollbars>
      </MapViewFlyOut>
    </>
  );
};
